/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nabídka pokojů"}>
        <SiteHeader />

        <Column className="css-zy8msw --center pb--30 pt--60" name={"wstęp"} style={{"backgroundColor":"var(--color-supplementary)"}} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":860}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 pb--25 pt--25" content={"<span style=\"color: white;\">Pokoje w ofercie</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"treść"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10 flex--center" style={{"maxWidth":1360}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/142/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/142/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/142/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/142/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/142/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/142/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box" content={"Pokój"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"Economy"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Subtitle className="subtitle-box mt--16" content={"33,— zł / noc"}>
              </Subtitle>

              <Button className="btn-box" content={"Rezerwacje"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/142/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/142/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/142/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/142/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/142/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/142/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box" content={"Pokój"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"Standard"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Subtitle className="subtitle-box mt--16" content={"33,— zł / noc"}>
              </Subtitle>

              <Button className="btn-box" content={"Rezerwacje"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/142/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/142/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/142/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/142/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/142/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/142/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box" content={"Pokój"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"Exclusive"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Subtitle className="subtitle-box mt--16" content={"33,— zł / noc"}>
              </Subtitle>

              <Button className="btn-box" content={"Rezerwacje"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Adres<br>"}>
              </Title>

              <Text className="text-box" content={"Hotel Grande<br>Pańska 18, Warszawa<br>Polska"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43 swpf--uppercase ls--0 lh--1" content={"Hotel<br>Grande<br>"}>
              </Title>

              <Button className="btn-box fs--16 swpf--uppercase" content={"Pokoje w ofercie"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+48 797 811 2X0<br>info@twoje-stronyy.com<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}